import { mapState } from "vuex";
import { getLang } from "@/services/http-service";

export default {
  computed: {
    ...mapState("language", ["languages", "selectedLanguage"])
  },
  methods: {
    displayLanguage(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedLanguage) {
        idToUse = this.selectedLanguage.id;
      }
      if (this.languages && this.languages.data && this.languages.data.length) {
        const filtered = this.languages.data.filter(
          item => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return filtered[0].name;
        } else {
          return null;
        }
      }
      return null;
    },
    getLanguage(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedLanguage) {
        idToUse = this.selectedLanguage.id;
      }
      if (this.languages && this.languages.data && this.languages.data.length) {
        const filtered = this.languages.data.filter(
          item => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return filtered[0];
        } else {
          return null;
        }
      }
      return null;
    },
    openLanguage(language, url) {
      this.$store.commit("language/setSelectedLanguage", language, {
        root: true
      });

      this.$router.push({
        name: url
      });
    },
    sortLanguages(a, b) {
      const currentLang = getLang();
      if (a.locale === currentLang && b.locale !== currentLang) {
        return -1;
      }
      if (a.locale !== currentLang && b.locale === currentLang) {
        return 1;
      }
      return 0;
    }
  }
};
