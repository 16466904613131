<template>
  <mobile-screen
    :header="true"
    screen-class="edit-form-screen edit-company icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <home-button />
          <router-link
            v-if="isEditable"
            :to="{ name: 'r_company-profile-change-photo' }"
            class="clebex-item-content-wrapper"
            ><div class="profile-photo-header-wrapper">
              <div
                class="photo-wrapper"
                :style="{
                  'background-image': `url(${previewPhoto || companyPhoto})`
                }"
                :class="{
                  'just-initials':
                    !previewPhoto && companyInitials && !companyPhoto
                }"
              >
                <div
                  class="initials"
                  v-if="!previewPhoto && companyInitials && !companyPhoto"
                >
                  {{ companyInitials }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("company-profile.company-profile.company-profile")
          }}
        </div>
        <template v-slot:right>
          <button v-if="isEditable" @click="initSubmit" :disabled="submitting">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <template v-if="companyProfile">
      <VeeForm @submit="submitForm" v-slot="{ errors }" class="form" novalidate>
        <ul class="clebex-item-section">
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.name }"
          >
            <span class="error-message" v-if="errors.first_name">
              {{ errors.first_name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="companyName"
                  >{{
                    displayLabelName("company-profile.company-profile.name")
                  }}
                  <span>*</span></label
                >
                <Field
                  name="name"
                  as="input"
                  type="text"
                  rules="required"
                  id="companyName"
                  :disabled="submitting || !isEditable"
                  v-model="companyProfile.name"
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.subdomain }"
          >
            <span class="error-message" v-if="errors.first_name">
              {{ errors.first_name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="companySubdomain"
                  >{{
                    displayLabelName(
                      "company-profile.company-profile.subdomain"
                    )
                  }}
                  <span>*</span></label
                >
                <Field
                  name="subdomain"
                  as="input"
                  type="text"
                  rules="required"
                  id="companySubdomain"
                  :disabled="submitting || !isEditable"
                  v-model="companyProfile.subdomain"
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.subdomain }"
          >
            <span class="error-message" v-if="errors.first_name">
              {{ errors.first_name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  for="companyEmailDomain"
                  >{{
                    displayLabelName(
                      "company-profile.company-profile.email-domain"
                    )
                  }}
                  <span>*</span></label
                >
                <Field
                  name="email_domain"
                  as="input"
                  type="text"
                  :disabled="submitting || !isEditable"
                  id="companyEmailDomain"
                  v-model="companyProfile.email_domain"
                />
              </div>
            </div>
          </li>
        </ul>
        <button type="submit" style="display: none;" ref="submitFormBtn">
          Submit form
        </button>
      </VeeForm>

      <ul class="clebex-item-section">
        <!---------------------------------->
        <!-- COUNTRY - START -->
        <!---------------------------------->
        <li class="clebex-item-section-item full-right-underline">
          <div
            v-if="isEditable"
            @click="openCountry(country, 'r_company-profile-select-country')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("company-profile.company-profile.country")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ country ? country.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("company-profile.company-profile.country")
            }}</label>
            <p class="text-label ellipsis">
              {{ country.name }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- COUNTRY - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- LANGUAGE - START -->
        <!---------------------------------->
        <li class="clebex-item-section-item full-right-underline">
          <div
            v-if="isEditable"
            @click="openLanguage(language, 'r_company-profile-select-language')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("company-profile.company-profile.language")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("company-profile.company-profile.language")
            }}</label>
            <p class="text-label ellipsis">
              {{ language.name }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- LANGUAGE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- CURRENCY - START -->
        <!---------------------------------->
        <li class="clebex-item-section-item full-right-underline">
          <div
            v-if="isEditable"
            @click="openCurrency(currency, 'r_company-profile-select-currency')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("company-profile.company-profile.currency")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ currency ? currency.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("company-profile.company-profile.currency")
            }}</label>
            <p class="text-label ellipsis">
              {{ currency.name }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- CURRENCY - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- TIMEZONE - START -->
        <!---------------------------------->
        <li class="clebex-item-section-item full-right-underline">
          <div
            v-if="isEditable"
            @click="openTimezone(timezone, 'r_company-profile-select-timezone')"
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("company-profile.company-profile.timezone")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{
                  timezone
                    ? timezone.name
                        .replace(/ \*/g, ", ")
                        .replace("UTC ", "UTC+")
                    : ""
                }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("company-profile.company-profile.timezone")
            }}</label>
            <p class="text-label ellipsis">
              {{ timezone.name.replace(/ \*/g, ", ").replace("UTC ", "UTC+") }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- TIMEZONE - END -->
        <!---------------------------------->
      </ul>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapGetters, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import currenciesMixin from "@/services/mixins/currencies/currencies-mixin";

export default {
  name: "CompanyProfile",
  data() {
    return {
      submitting: false,
      editing: false,
      previewPhoto: null,
      fileToUpload: null
    };
  },
  async created() {
    this.getCompanyProfile();
    this.setSelectedCurrency(null);
    this.setSelectedTimezone(null);
    this.setSelectedCountry(null);
    this.setSelectedLanguage(null);
  },
  computed: {
    ...mapState("companyProfile", ["companyProfile"]),
    ...mapGetters("companyProfile", ["companyPhoto"]),
    companyInitials() {
      if (this.companyProfile && this.companyProfile.name) {
        return this.companyProfile.name[0];
      }
      return null;
    },
    isEditable() {
      return this.can("COMPANY_PROFILE", "update");
    },
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else {
        return this.getLanguage(this.companyProfile.language_id);
      }
    },
    timezone() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else {
        return this.companyProfile.timezone;
      }
    },
    country() {
      if (this.selectedCountry) {
        return this.selectedCountry;
      } else {
        return this.getCountry(this.companyProfile.country_id);
      }
    },
    currency() {
      if (this.selectedCurrency) {
        return this.selectedCurrency;
      } else {
        return this.getCurrency(this.companyProfile.currency_id);
      }
    }
  },
  methods: {
    ...mapActions("companyProfile", ["getCompanyProfile", "setCompanyProfile"]),
    ...mapActions("country", ["setSelectedCountry"]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("timezone", ["setSelectedTimezone"]),
    ...mapActions("currency", ["setSelectedCurrency"]),
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values) {
        const data = {
          ...this.companyProfile,
          ...values
        };
        data.language_id = this.language.id;
        data.country_id = this.country.id;
        data.timezone_id = this.timezone.id;
        data.currency_id = this.currency.id;
        this.submitting = true;
        this.submitMasterData(data);
      }
    },
    submitMasterData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(`${apiEndpoints.company.companyProfile}`, values)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          if (this.fileToUpload) {
            this.uploadPhoto();
          }
        });
    },
    uploadPhoto() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const formData = new FormData();
      formData.append("image", this.fileToUpload);
      httpServiceAuth
        .post(apiEndpoints.company.companyProfileImage, formData, {
          headers: { "content-type": "multipart/form-data" }
        })
        .then(response => {
          this.$store.commit(
            "companyProfile/setCompanyProfilePhoto",
            response.data,
            {
              root: true
            }
          );
          this.cancelPhotoUpload();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    cancelPhotoUpload() {
      this.previewPhoto = null;
      this.fileToUpload = null;
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [countriesMixin, languagesMixin, timezonesMixin, currenciesMixin]
};
</script>
