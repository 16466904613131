import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("currency", ["currencies", "selectedCurrency"])
  },
  methods: {
    displayCurrency(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedCurrency) {
        idToUse = this.selectedCurrency.id;
      }
      if (
        this.currencies &&
        this.currencies.data &&
        this.currencies.data.length
      ) {
        const filtered = this.currencies.data.filter(
          item => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return (
            filtered[0].name +
            (filtered[0].symbol ? " (" + filtered[0].symbol + ")" : "")
          );
        } else {
          return null;
        }
      }
      return null;
    },
    getCurrency(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedCurrency) {
        idToUse = this.selectedCurrency.id;
      }
      if (
        this.currencies &&
        this.currencies.data &&
        this.currencies.data.length
      ) {
        const filtered = this.currencies.data.filter(
          item => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return filtered[0];
        } else {
          return null;
        }
      }
      return null;
    },
    openCurrency(currency, url) {
      this.$store.commit("currency/setSelectedCurrency", currency, {
        root: true
      });

      this.$router.push({
        name: url
      });
    }
  }
};
