import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("timezone", ["timezones", "selectedTimezone", "unsetTimezone"])
  },
  methods: {
    getTimezone(id) {
      if (this.timezones && this.timezones.data && this.timezones.data.length) {
        const filtered = this.timezones.data.filter(item => id === item.id);
        if (filtered && filtered.length) {
          return filtered[0];
        } else {
          return null;
        }
      }
      return null;
    },
    displayTimezone(id) {
      if (this.timezones && this.timezones.data && this.timezones.data.length) {
        const filtered = this.timezones.data.filter(item => id === item.id);
        if (filtered && filtered.length) {
          return filtered[0].name;
        } else {
          return null;
        }
      }
      return null;
    },
    openTimezone(timezone, url, params = null) {
      this.$store.commit("timezone/setSelectedTimezone", timezone, {
        root: true
      });

      this.$router.push({
        name: url,
        params: params
      });
    }
  }
};
