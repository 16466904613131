import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("country", ["countries", "selectedCountry"])
  },
  methods: {
    getCountry(id) {
      if (this.countries && this.countries.data && this.countries.data.length) {
        const filtered = this.countries.data.filter(item => id === item.id);
        if (filtered && filtered.length) {
          return filtered[0];
        } else {
          return null;
        }
      }
      return null;
    },
    displayCountryName(id) {
      if (this.countries && this.countries.data && this.countries.data.length) {
        const filtered = this.countries.data.filter(item => id === item.id);
        if (filtered && filtered.length) {
          return filtered[0].name;
        } else {
          return null;
        }
      }
      return null;
    },
    openCountry(country, url, params = null) {
      this.$store.commit("country/setSelectedCountry", country, {
        root: true
      });

      this.$router.push({
        name: url,
        params: params
      });
    }
  }
};
